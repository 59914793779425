/* eslint-disable */
import React from "react"

import loadable from "@loadable/component"
const Layout = loadable(() => import("components/layout"))
const Head = loadable(() => import("components/head"))
const NavWrapper = loadable(() =>
  import("components/pagination/nav-wrapper.css")
)
const NavButton = loadable(() => import("components/pagination/nav-button.css"))
const BookCard = loadable(() => import("./bookcard"))
const BookWrapper = loadable(() => import("./bookwrapper.css"))

import { Loader } from "./bookwrapper.css"
import { graphql } from "gatsby"
import { siteTitle } from "../../site-config"
const Header = loadable(() => import("components/header"));

// const OtherComponent = React.lazy(() => import('./OtherComponent'));
// const AnotherComponent = React.lazy(() => import('./AnotherComponent'));

const HomeList = page => {
  const { data, pageContext } = page
  const { currentPage, numPages } = pageContext
  const posts = data.allBooksJson.edges
  return (
    <div>
      <Header title={siteTitle} />
      <Layout fallback={<Loader/>}>
        <Head
          currentPage={currentPage}
          totalPages={numPages}
        />
        <div>
          <BookWrapper>
            {posts.map(book => (
              <BookCard
                className="book-card-item"
                key={book.node.id}
                {...book}
              />
            ))}
          </BookWrapper>
          <NavWrapper>
            {currentPage > 1 ? (
              <NavButton href={`/${currentPage - 1}`}>Prev</NavButton>
            ) : null}
            {currentPage < numPages ? (
              <NavButton href={`/${currentPage + 1}`}>Next</NavButton>
            ) : null}
          </NavWrapper>
        </div>
      </Layout>
    </div>
  )
}

export default HomeList

export const query = graphql`
  query BloglistQuery($skip: Int!, $limit: Int!) {
    allBooksJson(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          uri
          title
          original_title
          title_without_series
          description
          isbn
          isbn13
          num_pages
          large_image_url
          image_url
          small_image_url
          original_title
          isbn
          isbn13
          publication_year
          publication_month
          publication_day
          publisher
          average_rating
          ratings_count
          link
          authors {
            author {
              name
              large_image_url {
                _
              }
              image_url {
                _
              }
              small_image_url {
                _
              }
            }
          }
          content {
            childMarkdownRemark {
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
`

module.exports = {
    siteTitle: `Get Fresh Books`,
    siteTitleShort: `kahwa.club`,
    siteDescription: `Books Database`,
    siteUrl: `https://books-site-seo-template.pages.dev`,
    themeColor: `#000`,
    backgroundColor: `#fff`,
    pathPrefix: null,
    logo: __dirname + '/' + 'src/images/icon.png',
    social: {
        twitter: `nis10`,
        fbAppId: `966242223397117`,
    },
};
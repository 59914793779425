import React, { useEffect } from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export default styled.div `
  padding-top: 40px;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  height: 1650px;
  width: calc(100vw - 50px);
  padding: 25px;
  padding-bottom: 0;
  ${MEDIA.TABLET`
    height: auto;  
`}
`;

const LoaderStyled = styled.div `
  top: 50%;
  left: 50%;
  color: #ffffff;
  position: absolute;
  width: 10em;
  border-radius: 50%;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translate3d(-50%, -50%, 0);
&:before,
&:after {
  position: absolute;
  content: '';
  border-radius: 50%;
}
&:before {
  width: 5.2em;
  height: 10.2em;
  background: #07051a;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  animation: load 2s infinite ease 1.5s;
}
&:after {
  width: 5.2em;
  height: 10.2em;
  background: #07051a;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.0em;
  transform-origin: 0px 5.1em;
  animation: load 2s infinite ease;
}
@keyframes load {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

`;
export const Loader = ({bookloaderRef})=>{
  useEffect(()=>{
      if(bookloaderRef?.current?.style){
        bookloaderRef.current.style.display = 'none';
      }
  },[bookloaderRef]);
  return <LoaderStyled/>
}